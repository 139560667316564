<template>
  <div>
    <div class="game">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">数字游戏</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="content" v-html="con">
       
      </div>
    </div>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user";
export default {
  data() {
    return {
      con: "",
    };
  },
  created() {
    this.getcompany();
  },
  methods: {
    async getcompany() {
      let params = {
        barId: 12,
      };
      const data = await getbannerdetail(params);
      // console.log(data.data.portalArticleList[0].articleContent);
      this.con = data.data.portalArticleList[0].articleContent;
      var address='http://36.152.65.166:10008'
      // var imgReg = /<img.*?(?:>|\/>)/gi;
      //  var srcReg = /src=['"]?([^'"]*)['"]?/i;
    //  this.con.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function(match, p) {
    //    console.log(p);
    //     return `<img src=' ${address+p}'/>`;
    //   })
    var reg=new RegExp('/dev-api')
      this.con=this.con.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function(match, p) {
          
        return `<img src=' ${address+p.replace(reg,'')}'/>`;
      })
    },
  },
};
</script>

<style>
</style>
<style scoped>

</style>
<style lang="scss" scoped>

.product_title {
  width: 1500px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
    .tit1 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 13px;
    }
    .tit2 {
      width: 60px;
      height: 4px;
      background-color: #0088ea;
    }
  }
}
 ::v-deep img{
      max-width: 1100px!important;
    }
.game {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .content {
    width: 1100px!important;
    margin: 0 auto;
    margin-bottom: 80px;
     font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      text-align: justify;
      color: #000000;
      text-indent: 40px;
  
    
  }
}
</style>
